import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

import cn from './success.module.scss'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Thanks for Contacting Us!" />
      <h1>Thanks for Contacting Us</h1>
      <h3>We'll respond soon.</h3>
      <h3>
        <Link to="/" className={cn.link}>
          Home
        </Link>
      </h3>
    </Layout>
  )
}

export default IndexPage
